import React from 'react'
import { Link } from 'react-router-dom'
import routes from '../routes/routes'

const info = {
    pathname: "/concurso"
}

const SidebarConcursos = ({ pathname }) => {
  const parentRoute = routes.find((route) => route.path === "concurso");

  let links = [];

  parentRoute.children.map((route) => route.active && links.push({
    name: route.title,
    path: "/" + route.path
  }));
  
  links.splice(0, 0, {
    name: "Reseña",
    path: "/" + parentRoute.path
  })

  return (
    <aside className='sidebar-concursos'>
        <div className='sidebar-header'>
          <span className='header-title'>Concurso 2024</span>
        </div>
        <div className={`sidebar-body ${pathname !== info.pathname ? 'children' : 'std'}`}>

          <div className='side-card links'>
            <ul className='links-container arrow-list'>
            {
              links.map((link, index) => 
              <li className='list-item' key={index}>
                <Link className='list-link' to={ link.path === info.pathname ? link.path : info.pathname + link.path }>
                  {link.name}
                </Link>
              </li>
            )}
            </ul>
          </div>
        </div>
    </aside>
  )
}

export { SidebarConcursos }