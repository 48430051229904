import React from 'react'
import archivoListado from '../../assets/files/Concurso2024/LISTADO GANADORES DEPGFMLUZ 2024.pdf'

const ConcursoInscripciones = () => {

  return (
    <div className='page-section instructivos prueba-con'>
        {/* <h2 className='subpage-title'>Inscripción Ganadores Concurso 2024</h2> */}

        <section className='info-container listado-ganadores' id='index'>
          {/* <p className='heading-p'></p> */}
          
          <h3 className='section-title'>Listado de Ganadores del Concurso 2024</h3>

          <div className='info-group column'>
            <a 
              href={archivoListado} 
              download={'LISTADO GANADORES DEPGFMLUZ 2024'} 
              className='btn-baremo btn btn-primary link-button px-4'
            >
              Listado de Ganadores
            </a>
          </div>
          
          <div className='info-group'>
            <h3 className='group-title spaced'>Cronograma de Inscripción</h3>
            {/* <h3 className='modal-subtitle spaced'>Cronograma</h3> */}
            <p dangerouslySetInnerHTML={{__html: '<b>Horario: </b> 9:00 AM a 3:00 PM'}}/>
            <p dangerouslySetInnerHTML={{__html: '<b>Lugar:</b> Edificio Ciencia y Salud 2do piso'}}></p>
            <p>Según el terminal de documento de identidad: </p>
            <ul>
              <li className='span-group'>
                <span className='span-lead'>Miércoles: </span>
                <span>06/11/2024 No. 1, 2, 3, 4</span>
              </li>
              <li className='span-group'>
                <span className='span-lead'>Jueves: </span>
                <span>07/11/2024 No. 5, 6, 7</span>
              </li>
              <li className='span-group'>
                <span className='span-lead'>Viernes: </span>
                <span>08/11/2024 No. 8, 9, 0</span>
              </li>
            </ul>
          </div>
          <div className='info-group list-column'>
            <h4 className='group-title'>Pasos para realizar la inscripción: </h4>
            <ol className='unstyled-list lista-inscripcion'>
              <li>1. Llenar el formulario de inscripción ubicado en nuestra página web. </li>
              <li>2. Administración se comunicará con Usted vía WhatsApp, para indicarle los aranceles de inscripción. </li>
              <li>3. Inscripción presencial en la DEPG, según el terminal de documento de la identidad. </li>
              <li>4. Retirar la constancia que lo acredita como ganador del Concurso 2024, requisito obligatorio para la entrevista. </li>
            </ol>
          </div>
          <div className='info-group list-column'>
            <h4 className='group-title'>Entrega de la Carta de Asignación de Cupo: </h4>
            <div className='span-group'>
              <span className='span-lead'>Día: </span>
              <span>sábado 04 de enero de 2025.</span>
            </div>
          </div>

          <div className='info-group list-column'>
            <h4 className='group-title'>Inicio de Clases</h4>
            <p>LUNES 06 DE ENERO DE 2025</p>
          </div>

          <div className='info-group list-column'>
            <p className='name-p lead-name'>Dr. Freddy Pachano Arenas<br/>Director</p>
            <p className='name-p'>Dra. Jorymar Leal<br/>Secretaria Docente</p>
          </div>
        </section>
    </div>
  )
}

export { ConcursoInscripciones }