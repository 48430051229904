import React from 'react'
import { Button, Modal } from 'react-bootstrap';
// import img_modal from '../../assets/images/ModalInicio/BannerProgramasPEC-Modal.png'

const links = {
  formPruebaIngles: 'https://forms.gle/2a8j1EUtFQMhqKGk9',
  formPruebaSalud: 'https://forms.gle/fU2PewLENLaRyCvm7'
}

const ModalPec = ({ toggleFunction, modalState }) => {
  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title>Cronograma de Actividades</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container text-center'>
          {/* <img src={img_modal} alt='Apertura del Concurso de Posgrado de Medicina 2024' className='modal-banner' /> */}

          <h3 className='heading-title mb-4'>Programa de Educación Continua (PEC)</h3>

          <div className='cards-group'>
            <div className='info-group card'>
              <h3 className='modal-subtitle continuous heading'>Prueba de Suficiencia de Inglés</h3>
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Fecha</b>: Jueves 10 de abril de 2025'}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Hora</b>: 2:00 PM'}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Lugar</b>: Edificio Ciencia y Salud, Salón de Usos Múltiples.'}} />
              <p className='info-p spaced-md' dangerouslySetInnerHTML={{__html: '<b>Coordinadora de Inglés</b>: Prof. Virginia Novoa'}} />
              
              <p className='info-p fw-semibold'>Para realizar la prueba necesitarás:</p>
              <ul className='unstyled-list spaced-md'>
                <li className='list-item'>✔  Hoja de examen</li>
                <li className='list-item'>✔  Lápiz grafito</li>
                <li className='list-item'>✔  Diccionario de papel</li>
              </ul>

              <a href={links.formPruebaIngles} target='blank' className='btn btn-primary link-button rounded-pill center'>
                Formulario de Inscripción
              </a>
            </div>

            <div className='info-group card'>
              <h3 className='modal-subtitle continuous heading'>Prueba de Salud Mental</h3>

              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Fecha</b>: 11 de abril de 2025'}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Hora</b>: 8:00 AM'}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Lugar</b>: Edificio Ciencia y Salud, Salón de Usos Múltiples.'}} />
              <p className='info-p spaced-md' dangerouslySetInnerHTML={{__html: '<b>Coordinador</b>: Dr. Cesar Marín'}} />

              <a href={links.formPruebaSalud} target='blank' className='btn btn-primary link-button rounded-pill center'>
                Formulario de Inscripción
              </a>
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalPec }