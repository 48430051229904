import React, { useState } from 'react'
import video_instructivo_concurso  from '../../assets/videos/Video Instructivo - Concurso Doctorados 2024.mp4'
import ReactPlayer from 'react-player'
import baremoPdf from '../../assets/files/Concurso2024/BAREMO 2024 DOCTORADOS FP.pdf'

const ConcursoDoctorados = () => {

  const programas = [
    {
        nombre: "Doctorado en Ciencias Médicas",
        path: "/posgrado/doctorados/doctorado-ciencias-medicas"
    },
    {
        nombre: "Doctorado en Ciencias de la Salud",
        path: "/posgrado/doctorados/doctorado-ciencias-salud"
    },
    {
        nombre: "Doctorado en Metabolismo Humano",
        path: "/posgrado/doctorados/doctorado-metabolismo-humano"
    }
  ];

  const baremo = {
    url: baremoPdf,
    fileName: 'BAREMO 2024'
  };

  const [videoReadyStatus, setVideoReadyStatus] = useState(false);
  
  const toggleVideoStatus = () => {
    setVideoReadyStatus(true);
    // console.log('Video status: ' + videoReadyStatus)
  }

  return (
    <div className='page-section instructivos doctorados'>
        <h2 className='subpage-title'>Concurso Doctorados 2024</h2>

        <section className='video-container'>
          <div className={`video-canvas ${ videoReadyStatus ? 'ready' : 'loading' }`}>
            <button 
              className={`video-placeholder btn btn-secondary disabled placeholder-wave d-${videoReadyStatus ? 'none' : 'block'}`} 
            ></button>
            <div className={`d-${ videoReadyStatus ? 'block' : 'none' }`}>
              <ReactPlayer
                className='video-player'
                url={video_instructivo_concurso}
                playing={true}
                volume={0.3}
                controls={true}
                width={'100%'}
                height={'auto'}
                onReady={toggleVideoStatus}
              />
            </div>
          </div>
          <div className='button-group d-flex flex-column'>
            <a href={baremo.url} download={baremo.fileName} className='btn-baremo btn btn-primary link-button px-4'>
              Baremo 2024
            </a>
          </div>
        </section>

        <section className='info-container'>
          {/* <p className='heading-p'></p> */}
          
          <div className='info-group d-flex flex-column'>
            <h3 className='modal-subtitle mb-4'>Programas</h3>
            {
                programas.map((programa, index) =>
                    <a key={index} href={programa.path} target='blank' className='info-p fw-semibold sublink'>
                        {programa.nombre}
                    </a>
                )
            }
          </div>
          <div className='info-group'>
            <h3 className='modal-subtitle spaced'>Cronograma</h3>
            <h4 className='highlight-title'>Recepción de Credenciales</h4>
            <p className='info-p mb-0'>08 al 26 de octubre de 2024</p>
          </div>
          <div className='info-group'>
            <h4 className='highlight-title'>Entrevista:</h4>
            <p className='info-p mb-0'>19 al 22 de noviembre de 2024</p>
          </div>
          <div className='info-group spaced'>
            <h3 className='highlight-title'>Publicación de Resultados</h3>
            <p className='info-p mb-0'>10 al 14 de diciembre de 2024</p>
          </div>
          <div className='info-group'>
            <p className='fw-bold'>Inicio de Clases: 10 de febrero de 2025</p>
          </div>
        </section>
    </div>
  )
}

export { ConcursoDoctorados }